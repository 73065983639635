<template>
  <div v-if="visible" class="email-modal-mask" @click="closeModal()">
    <div class="email-modal" @click.stop>
      <div class="text-right pt-4 pr-4">
        <img src="../assets/icons/ic_close.svg"
             alt=""
             class="cursor-pointer"
             @click="closeModal()"
             height="20">
      </div>

      <div class="px-5 py-5">

        <div class="row justify-content-center">
          <img src="../assets/images/ic_download_cloud.svg" alt="" width="77">
        </div>

        <div class="text-center py-3 px-0 px-md-5 headline">
          {{ title }}
        </div>

        <div class="pt-3 px-0 px-sm-5">
          <BFormInput v-model="email"
                      placeholder="E-Mail Adresse"
                      @change="$v.email.$touch()"
                      :state="$v.email.$dirty ? !$v.email.$error : null"/>
          <BFormInvalidFeedback>
            Bitte geben Sie eine valide E-Mail Adresse an
          </BFormInvalidFeedback>
        </div>
        <div class="pt-4 px-0 px-sm-5 text-left">
          <BFormCheckbox
              id="checkbox-1"
              v-model="acceptedTerms"
              name="checkbox-1"
              variant="primary"
              :value="true"
              :unchecked-value="false"
              :state="$v.acceptedTerms.$dirty ? !$v.acceptedTerms.$error : null"
          >
            <div class="font-weight-bold" style="font-size: 14px">
              Ich bin mir darüber bewusst, dass es sich bei
              {{ intent === 'report' ? 'dem Analysebericht' : 'der Datenschutzerklärung' }} um
              einen automatisiert generierten Mustertext handelt und bitte um Zusendung
              {{ intent === 'report' ? 'meines Analyseberichts' : 'meiner Datenschutzerklärung' }}
              per E-Mail.
            </div>
            Ich bin damit einverstanden, dass PRIVE mir
            {{ language === 'CH' ? 'regelmässig' : 'regelmäßig' }} aktuelle News und Angebote aus
            den Bereichen Datenschutz,
            Abmahnschutz und Internetrecht per E-Mail zukommen lässt. Hiergegen kann ich jederzeit
            form- und kostenlos widersprechen.
            Details in unserer <a href="https://www.prive.eu/datenschutz" target="_blank">Datenschutzerklärung</a>.
          </BFormCheckbox>
        </div>
        <div class="pt-2">
          <BAlert :show="emailSent"
                  variant="success"
                  class="m-0 mt-2 py-2">
            <div class="text-center">
              {{ emailSentText }}
            </div>
          </BAlert>
          <BAlert :show="emailError"
                  variant="danger"
                  class="m-0 mt-3 py-2 row justify-content-center align-items-center">
            <span class="pl-2">
                  Es ist ein Fehler aufgetreten!
            </span>
          </BAlert>
        </div>
        <div v-if="!emailSent" class="pt-4 text-center">
          <BButton class="text-uppercase text-white pl-4"
                   @click="click"
                   :disabled="emailSending"
                   pill
                   variant="secondary">
            <img class="pr-3" src="../assets/icons/ic_download.svg" alt="">
            <span>
              {{ downloadText }}
              <BSpinner v-if="emailSending"
                        variant="lighter-gray"
                        class="ml-2" style="width: 20px; height: 20px;"/>
            </span>
          </BButton>
        </div>
        <div v-if="emailSent" class="pt-4 text-center">
          <BButton variant="link" @click="closeModal" style="color: #BCBCBC">
            Schliessen
          </BButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BAlert,
  BSpinner
} from "bootstrap-vue";
import {required, email, sameAs} from 'vuelidate/lib/validators'
import {validationMixin} from "vuelidate";
import Api from "@/legal-website-scanner/services/Api";

export default {
  name: "EmailModal",
  props: ['language'],
  components: {BFormInput, BFormInvalidFeedback, BFormCheckbox, BButton, BAlert, BSpinner},
  mixins: [validationMixin],
  validations: {
    email: {required, email},
    acceptedTerms: {sameAs: sameAs(() => true)}
  },
  data() {
    return {
      currentUUID: null,
      intent: null,
      email: null,
      visible: false,
      acceptedTerms: false,
      emailSent: false,
      emailSending: false,
      emailError: false
    }
  },
  computed: {
    documentName() {
      return this.intent !== 'report' ? 'Datenschutzerklärung' : 'Analysebericht'
    },
    title() {
      return `${this.intent !== 'report' ? 'Ihre' : 'Ihr'} ${this.documentName}`
    },
    emailSentText() {
      return `${this.title} wurde an ${this.email} versendet.`
    },
    downloadText() {
      return `${this.documentName} ${this.intent !== 'report' ? 'herunterladen' : 'anfordern'}`
    }
  },
  methods: {
    show(currentUUID, intent) {
      this.currentUUID = currentUUID
      this.intent = intent
      this.emailSent = false
      this.emailSending = false
      this.emailError = false
      this.visible = true
    },
    closeModal() {
      this.visible = false
    },
    click() {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }
      this.emailSent = false
      this.emailError = false
      this.emailSending = true
      Api.sendMail({
        email: this.email,
        intent: this.intent,
        websiteScanUUID: this.currentUUID
      }, () => {
        this.emailSent = true
        this.emailSending = false
      }, error => {
        console.log(error)
        this.emailError = true
        this.emailSending = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.email-modal-mask {
  z-index: 10;
  position: absolute;
  padding: 0px 18px;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.email-modal {
  position: relative;
  margin: 0 auto;
  z-index: 10;
  top: 50px;
  width: 100%;
  max-width: 756px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.13);
}
</style>
