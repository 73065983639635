<template>
<div v-if="visible && errorType" class="error-modal-mask" @click="closeModal">
  <div class="error-modal p-4 pb-5" @click.stop>
    <div class="text-right">
      <img src="../assets/icons/ic_close.svg"
           alt=""
           class="cursor-pointer"
           @click="closeModal"
           height="20">
    </div>
    <div class="row justify-content-center">
      <img v-if="priveScanAttempt" src="../assets/images/img_prive_scan_2x.png" alt="" width="120" height="86">
      <img v-if="duplicateScanAttempt" src="../assets/images/ic_laptop_muede.svg" alt="" width="120" height="86">
    </div>
    <div class="text-center font-weight-bold pt-4 headline">
      <div v-if="priveScanAttempt">
        Oh! Sie wollen die PRIVE-Webseite scannen?
      </div>
      <div v-if="duplicateScanAttempt">
        Oh! Der kostenlose PRIVE <br> Website-Scanner ist erschöpft
      </div>
    </div>
    <div class="text-center pt-4 text-gray">
      <div v-if="priveScanAttempt">
        Das haben wir doch längst gemacht. Der PRIVE-Scanner freut sich aber schon sehr auf Ihre Webseite.
      </div>
      <div v-if="duplicateScanAttempt">
        Scannen Sie viele weitere Webseiten in Ihrem nagelneuen PRIVE-Account.
      </div>
    </div>
    <div class="text-center pt-4">
      <div v-if="priveScanAttempt">
        <BButton class="text-uppercase text-white"
                 style="padding: 10px 40px 5px 40px"
                 pill
                 variant="secondary"
                 @click="closeModal">
          Zurück zum Webseiten-Scanner
        </BButton>
      </div>
      <div v-if="duplicateScanAttempt">
        <BButton class="text-uppercase text-white"
                 style="padding: 10px 40px 5px 40px"
                 pill
                 :href="flow !== 'prive' ? 'https://www.prive.eu#preise' : ''"
                 :target="flow === 'prive-web' ? '' : '_blank'"
                 variant="secondary">
          Mehr Infos
        </BButton>
        <div class="text-center pt-4">
          <BButton class="text-uppercase text-muted"
                   @click="closeModal"
                   variant="link">
            Zu den letzten Ergebnissen
          </BButton>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {BButton} from "bootstrap-vue";

export default {
  name: "ErrorModal",
  props: ['flow'],
  components: {BButton},
  data() {
    return {
      visible: false,
      errorType: null
    }
  },
  computed: {
    priveScanAttempt() {
      return this.errorType === 'priveScanAttempt'
    },
    duplicateScanAttempt() {
      return this.errorType === 'duplicateScanAttempt'
    }
  },
  methods: {
    show(errorType) {
      this.visible = true
      this.errorType = errorType
    },
    closeModal() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.error-modal-mask {
  z-index: 10;
  position: absolute;
  padding: 0px 18px;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.error-modal {
  position: relative;
  margin: 0 auto;
  top: 15%;
  z-index: 10;
  width: 100%;
  max-width: 756px;
  border: 1px solid #EBEBEB;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.13);
}
</style>
