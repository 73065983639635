<template>
  <div>
    <div v-if="loading" class="py-5 row justify-content-center">
      <BSpinner variant="secondary"
                class="custom-spinner"/>
    </div>

    <BAlert :show="error"
            variant="danger"
            class="m-0 mt-2 py-2">
      <div class="text-center">
        {{errorMessage}}
      </div>
    </BAlert>

    <div v-if="!loading && !error" class="px-0 px-md-5">
      <div class="text-center">

        <div class="headline pb-4">
          Tadaa! Ihre {{ language === 'CH' ? 'DSG- und DSGVO-konforme' : 'DSGVO-konforme' }}
          <br> Datenschutzerklärung ist fertig
        </div>
        <div class="pb-5">
          Sie können die Datenschutzerklärung jederzeit kostenfrei auf Ihrer Webseite nutzen.
          <br><br>
          Hinweis: Bitte prüfen Sie vor der Einbindung in ihre Webseite noch einmal, ob alle Angaben korrekt sind.
          Binden Sie die Datenschutzerklärung dann unter einem eigenen Menüpunkt "Datenschutzerklärung" auf Ihrer Webseite ein.
        </div>

        <div class="bg-gray p-4">
          <div class="text-primary sub-headline pb-3" style="line-height: 33px">
            Hat Ihnen im PRIVE-Generator etwas gefehlt oder haben Sie Fragen zum Einsatz bestimmter Technologien?
          </div>
          <div class="text-center text-md-left" style="padding: 0 15px">
            Wir lassen Sie natürlich nicht im Regen stehen. Schreiben Sie uns einfach eine E-Mail an
            <a :href="`mailto: ${language === 'CH' ? 'support@prive.law' : 'support@prive.eu'}`">
              {{ language === 'CH' ? 'support@prive.law' : 'support@prive.eu' }}.
            </a>
            Unsere Rechtsanwälte und Datenschutzbeauftragten helfen Ihnen bei allen
            {{ language === 'CH' ? 'DSG- und DSGVO-' : 'DSGVO-' }}Sorgen.
          </div>
        </div>

        <div v-if="dataAgreementHtml" class="pt-5">
          <div v-html="dataAgreementHtml"
               class="px-4 py-5 text-left bg-gray"
               style="border: 1px solid #E0E0E0;">
          </div>

          <div class="pt-5 row justify-content-between">
            <div class="col-12 py-3">
              <BButton class="py-2 px-4 text-white text-uppercase"
                       :variant="htmlCopied ? 'success' : 'primary'"
                       pill
                       @click="copyToClipboard">
                <img src="../assets/icons/ic_copy_code.svg"
                     height="16"
                     alt="">
                <span class="pl-2">{{ htmlCopied ? 'HTML-Code kopiert' : 'HTML-Code kopieren' }}</span>
                <img v-if="htmlCopied" class="pl-2"
                     src="../assets/icons/ic_check.svg"
                     height="13"
                     alt="">
              </BButton>
            </div>
            <div class="col-12 pb-3">
              <BButton class="py-2 px-4 text-white text-uppercase"
                       pill
                       @click="download">
                <img src="../assets/icons/ic_download.svg"
                     height="16"
                     alt="">
                <span class="pl-2">Als PDF herunterladen</span>
              </BButton>
            </div>
            <div v-if="intent === 'dse'" class="col-12 pb-4">
              <BButton class="py-2 px-4 text-white new-agreement-btn text-uppercase"
                       :href="flow !== 'prive' ? '#scanner' : ''"
                       pill
                       @click="createNewPrivacyAgreement">
                <span>Neue Datenschutzerklärung</span>
              </BButton>
            </div>
            <div v-if="flow !== 'prive'" class="col-12 pt-4">
              <div class="row justify-content-center pb-3 px-0 px-md-5">
                <div style="max-width: 720px">
                  <b>Sie wollen wissen, wie man die DSGVO schnell, einfach und ohne teure Anwälte umsetzen kann?</b>
                  Dann sichern Sie sich Ihren kostenlosen Platz im Webinar "DSGVO-konform in 7 Schritten" mit Rechtsanwalt Alex Goldberg.
                </div>
              </div>
              <BButton class="py-2 px-4 text-white text-uppercase"
                       href="https://www.prive.eu/webinar-dsgvo/"
                       variant="success"
                       target="_blank"
                       pill>
                <span>Zum DSGVO-Webinar anmelden</span>
              </BButton>
            </div>
            <div v-if="flow === 'prive'" class="col-12 text-uppercase">
              <BButton variant="link" @click="$emit('closeScanner')" style="color: #BCBCBC">
                Schliessen
              </BButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {BButton, BSpinner, BAlert} from "bootstrap-vue";
import VueClipboard from 'vue-clipboard2'
import Api from "@/legal-website-scanner/services/Api";

Vue.use(VueClipboard)

export default {
  name: "PrivacyAgreementDownload",
  components: {BButton, BSpinner, BAlert},
  props: ['flow', 'intent', 'currentUUID', 'language'],
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: 'Es ist ein Fehler aufgetreten!',
      legalDocumentId: null,
      dataAgreementHtml: null,
      htmlCopied: false,
      copied: false,
      baseURL: process.env.VUE_APP_API_BASE_URL
    }
  },
  mounted() {
    this.loading = true

    Api.legalDocument(this.currentUUID, response => {
      this.legalDocumentId = response.data.id
      Api.legalDocumentHTML(this.legalDocumentId, response => {
        this.dataAgreementHtml = response.data
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
        this.error = true
        this.errorMessage = 'Dokument konnte nicht geladen werden!'
      })
    }, error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    copyToClipboard() {
      this.$copyText(this.dataAgreementHtml).then(() => {
        this.htmlCopied = true
      }, error => {
        console.log(error)
      })
    },
    createNewPrivacyAgreement() {
      this.$emit('createNewPrivacyAgreement')
    },
    download() {
      window.open(`${this.baseURL}/api/cockpit/resources/legaldocument/${this.legalDocumentId}/render/pdf`, '_blank')
    }
  },
}
</script>

<style scoped lang="scss">
@import "../styles/theme";

.bg-gray {
  background-color: #F9F9F9 !important;
}
.new-agreement-btn {
  background-color: $dark-gray !important;
  border-color: $dark-gray !important;
}

</style>
