<template>
  <div class="px-0">
    <ErrorModal :flow="flow" ref="errorModal"/>
    <div class="row align-items-end">
      <div class="p-0 col-12 col-lg-6">
        <div class="text-uppercase headline">
          SCANNEN SIE JETZT
          <br>
          IHRE WEBSEITE
        </div>
        <div class="pt-2 open-sans">
          Der automatische PRIVE Website-Scanner erkennt automatisch,
          welche Technologien Ihre Webseite nutzt, konfiguriert Ihren Datenschutz
          und erstellt die notwendigen Datenschutzdokumente.
          <br>
          Jetzt ausprobieren:
        </div>
        <div class="pt-3">
          <BFormInput v-model="url"
                      placeholder="Website-URL"
                      :disabled="!!urlProp"
                      @keypress.enter="scanWebsite"
                      @input="$v.url.$touch()"
                      :state="$v.url.$dirty ? !$v.url.$error : null"/>
          <BFormInvalidFeedback>
            Bitte geben Sie eine korrekte URL ein. (http:// bzw. https://)
          </BFormInvalidFeedback>
        </div>
        <div class="pt-3 text-center text-lg-left">
          <BButton class="text-uppercase text-white"
                   style="padding: 10px 40px 5px 40px"
                   pill
                   @click="scanWebsite"
                   :disabled="$v.url.$error || !scanAvailable"
                   variant="secondary">
            Jetzt loslegen
          </BButton>
        </div>
      </div>

      <!-- COMPUTER-IMAGE -->
      <div class="col-12 col-lg-6 mt-4 pt-lg-0" :class="{scanning: loading}">
        <div class="laser"></div>
        <img src="../assets/images/img_laptop.png" class="laptop-img">

        <!-- Laptop Screen Images-->
        <div v-if="screenshot">
          <img :src="screenshot.imageBase64Encoded" class="laptop-screen-img">
        </div>
        <div v-else>
          <img v-if="!loading" src="../assets/images/img_scan_1.png" class="laptop-screen-img">
          <img v-if="loading" src="../assets/images/img_scan_2.png" class="laptop-screen-img">
        </div>
      </div>

      <div class="col pt-5 px-0" v-if="error">
        <BAlert :show="error"
                variant="danger"
                class="m-0 mt-3 py-2 row justify-content-center align-items-center">
          <span class="pl-2">
                Es ist ein Fehler aufgetreten!
          </span>
        </BAlert>
      </div>
    </div>

    <!--ToolCards, Continue-Button -->
    <div v-if="!error" class="pt-4 mt-2 p-0">
      <div v-if="loading || tools">

        <div class="text-uppercase sub-headline">
          Ergebnisse
        </div>

        <ToolCards :tools="tools" :loading="loading" :intent="intent" :language="language"/>

        <BAlert v-if="intent !== 'itrk' && !loading && tools && (tools.externalURLs.length || tools.externalCookieDomains.length)"
                :show="true"
                variant="warning"
                class="external-tools-alert m-0 mt-3 mb-4">
          <div class="pl-2">
            <img src="../assets/icons/ic_achtung.svg" height="17">

            Achtung: Wir haben beim Aufruf der Webseite

            <!--External-URL Text and Popover-->
            <span v-if="tools.externalURLs.length">
                <b>{{ tools.externalURLs.length }} aktive ausgehende  {{tools.externalURLs.length === 1 ? 'Verbindung' : 'Verbindungen'}}</b>
                <Popover>
                  <template v-slot:popover-trigger-element>
                    <span>ⓘ</span>
                  </template>

                  <template v-slot:popover-content>
                    <div class="text-left p-3 custom-scrollbar warning-popover">
                      <div class="font-weight-bold pb-3" style="font-size: 16px">Aktive ausgehende Verbindungen</div>
                      <div v-for="(tool, index) in tools.externalURLs" :key="index" class="text-truncate pb-2">
                        <b>{{ index + 1 }}.</b> {{ tool }}
                      </div>
                    </div>
                  </template>
                </Popover>
              </span>

            {{tools.externalURLs.length && tools.externalCookieDomains.length ? 'und' : ''}}

            <!--External-Cookie-Domains Text and Popover-->
            <span v-if="tools.externalCookieDomains.length">
                <b>{{ tools.externalCookieDomains.length }} {{tools.externalCookieDomains.length === 1 ? 'gesetztes Third-Party-Cookie' : 'gesetzte Third-Party-Cookies'}}</b>
                <Popover>
                  <template v-slot:popover-trigger-element>
                    <span>ⓘ</span>
                  </template>

                  <template v-slot:popover-content>
                    <div class="text-left p-3 custom-scrollbar warning-popover">
                      <div class="font-weight-bold pb-3" style="font-size: 16px">Gesetzte Third-Party-Cookies</div>
                      <div v-for="(tool, index) in tools.externalCookieDomains" :key="index" class="text-truncate pb-2">
                        <b>{{ index + 1 }}.</b> {{ tool }}
                      </div>
                    </div>
                  </template>
                </Popover>
              </span>

            entdeckt. Bitte beachten Sie, dass solche Datentransfers ohne vorherige
            Einwilligung des Besuchers in der Regel unzulässig sind. Bitte prüfen Sie die Konfiguration Ihres Cookie-Consent-Tools.
          </div>
        </BAlert>

        <div class="mt-3 text-center">
          <BAlert :show="true"
                  variant="danger"
                  class="d-block d-lg-none">
            <div class="text-center">
              Auf Mobilgeräten können Sie den Datenschutz-Generator leider nicht verwenden.
              <br>
              Bitte wechseln Sie hierfür auf ein Desktop-Gerät.
            </div>
          </BAlert>
          <BButton class="text-uppercase text-white px-5 py-2 d-none d-lg-inline"
                   :href="flow !== 'prive' ? '#scanner' : ''"
                   @click="nextStep"
                   pill
                   :disabled="!tools || loading"
                   variant="secondary">
            Weiter
          </BButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, url} from 'vuelidate/lib/validators'

import ErrorModal from "@/legal-website-scanner/components/ErrorModal";
import Api from "@/legal-website-scanner/services/Api";
import {BAlert, BButton, BFormInput, BFormInvalidFeedback} from "bootstrap-vue";
import ToolCards from "@/legal-website-scanner/components/ToolCards";
import Popover from "@/legal-website-scanner/components/Popover";

export default {
  name: "Scanner",
  props: ['flow', 'intent', 'screenshot', 'currentUUID', 'tools', 'urlProp', 'scanAvailable', 'origin', 'language'],
  components: {ErrorModal, Popover, ToolCards, BFormInput, BFormInvalidFeedback, BButton, BAlert},
  mixins: [validationMixin],
  validations: {
    url: { required, url }
  },
  data() {
    return {
      loading: false,
      error: false,
      url: 'https://'
    }
  },
  mounted() {
    this.url = this.urlProp || this.url
  },
  methods: {
    scanWebsite() {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }
      this.loading = true
      this.error = false

      let websiteScanForm = {
        url: this.url,
        websiteScanUUID: this.currentUUID,
        origin: this.origin
      }

      Api.scanWebsite(websiteScanForm, response => {
        this.$emit('handleWebsiteScan', response.data)
        this.loading = false
      }, error => {
        if (!error.response) {
          this.error = true
          this.loading = false
          return
        }
        if (error.response.status === 401) {
          this.$refs.errorModal.show('priveScanAttempt')
        } else if (error.response.status === 403) {
          this.$refs.errorModal.show('duplicateScanAttempt')
        } else {
          console.log(error)
          this.error = true
        }
        this.loading = false
      })
    },
    nextStep() {
      this.$emit('nextStep')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

.external-tools-alert.alert-warning {
  border: 1px solid $warning;
}

.warning-popover {
  max-width: 300px;
  max-height: 200px;
  overflow-y: auto;
}
// Laptop Scan Animation
$laptop-width: 100%;
$laptop-height: 100%;

$laptop-screen-width: $laptop-width * 0.69;
$laptop-screen-height: $laptop-height * 0.745;
$laptop-screen-left-offset: $laptop-width * 0.161;
$laptop-screen-top-offset: $laptop-height * 0.1;

.laptop-img {
  width: $laptop-width;
  // has to be auto... Safari is stupid af <3...
  // 100% height will cause the img to stretch aaaaall da way not giving
  // a flying fuck
  height: auto;
  position: relative;
}

.laptop-screen-img {
  position: absolute;
  max-width: $laptop-screen-width;
  height: $laptop-screen-height;
  left: $laptop-screen-left-offset;
  top: $laptop-screen-top-offset;
}

.scanning {
  .laser {
    position: absolute;
    z-index: 2;
    width: $laptop-screen-width;
    left: $laptop-screen-left-offset;
    top: $laptop-screen-top-offset;
    height: 2px;
    background-color: rgba(255, 99, 71, .07);
    border-bottom: 2px rgba(255, 0, 0, .8) solid;
    animation: scanning 4s infinite;
  }
}

@keyframes scanning {
  50% {
    height: $laptop-screen-height;
  }
}
</style>
