<template>
  <div class="text-center">

    <EmailModal ref="emailModal"
                :language="language"
    />


    <!-- Header Section -->
    <div>

      <div v-if="intent !== 'report'">
        <h1 class="headline pt-5">
          Der PRIVE-Scanner hat Ihren Datenschutz vorbereitet.
        </h1>
        <div class="info-text pb-3" style="font-family: OpenSans-Regular">
          Wir haben die Plugins und Tools für das Consent-Tool,
          die DSGVO-konforme Datenschutzerklärung und die wichtigsten Datenschutzdokumente für Sie vorbereitet.
        </div>
      </div>

      <div v-if="intent === 'report'">
        <h1 class="headline pt-5">
          Der PRIVE-Scanner hat Ihre Webseite gescannt und analysiert.
        </h1>
        <div class="info-text pb-3" style="font-family: OpenSans-Regular">
          Wir haben die Plugins und Tools für das Consent-Tool,
          die notwendigen Inhalte für die Datenhschutzerklärung
          und die benötigten Datenschutzdokumente für Sie analysiert.
        </div>
      </div>

    </div>


    <!-- ToolCards Section -->
    <ToolCards @showEmailModal="showEmailModal"
               :language="language"
               :is-last-step="true"
               :flow="flow"
               :intent="intent"
               :hasDSE="!!legalDocument"/>

    <!-- Sub-Cards Section -->
    <div class="inquiry-text py-4" style="font-family: UniviaPro-Medium">
      <div v-if="intent !== 'report'">
        <div v-if="flow !== 'prive'">Eine sichere Datenschutzerklärung ist nur die halbe Miete.</div>
        Wollen Sie Ihren Datenschutz vollständig mit PRIVE konfigurieren?
      </div>

      <div v-if="intent === 'report'">
        Der PRIVE-Analysebericht ist bereits für Sie erstellt worden.
      </div>
    </div>

    <!-- Buttons Section -->
    <div>

      <div v-if="intent === 'report'">
        <div class="pt-3">
          <BButton :href="flow !== 'prive' ? '#scanner' : ''"
                   @click="showEmailModal"
                   variant="success"
                   class="py-2 px-5 text-uppercase text-white"
                   pill>
            Analysebericht anfordern
          </BButton>
        </div>
      </div>

      <div v-if="intent !== 'report' && flow !== 'prive'">
        <div class="pb-3" style="max-width: 300px; height: 48px; margin: auto">
          <BButton :href="flow !== 'prive' ? 'https://www.prive.eu/#preise': ''"
                   :target="flow === 'prive-web' ? '' : '_blank'"
                   variant="secondary"
                   class="py-2 px-3 text-uppercase text-white"
                   pill
                   block>
            Jetzt Details ansehen
          </BButton>
        </div>
        <div class="pt-3">
          <BButton :href="flow !== 'prive' ? '#scanner' : ''"
                   @click="showEmailModal"
                   variant="success"
                   class="py-2 px-5 text-uppercase text-white"
                   pill>
            <div class="text-uppercase row justify-content-center align-items-center">
              <div class="pt-1 pr-2">
                Nein, ich möchte nur meine Datenschutzerklärung!
              </div>
              <div>
                <img src="../assets/icons/ic_arrow_right_white.svg" height="13" alt="">
              </div>
            </div>
          </BButton>
        </div>
      </div>
      <div v-if="intent !== 'report' && flow === 'prive'">
        <div class="pb-3" style="max-width: 300px; height: 48px; margin: auto">
          <BButton @click="applyScanResult"
                   variant="secondary"
                   class="py-2 px-3 text-uppercase text-white"
                   pill
                   block>Konfiguration speichern
          </BButton>
        </div>
        <div class="text-uppercase download-link-text pt-4">
          <BButton variant="link" @click="close" style="color: #BCBCBC">
            <div class="text-uppercase row justify-content-center align-items-center">
              <div class="pt-1 pr-2">
                Abbrechen
              </div>
            </div>
          </BButton>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ToolCards from "@/legal-website-scanner/components/ToolCards";
import {BButton} from "bootstrap-vue";
import EmailModal from "@/legal-website-scanner/components/EmailModal";

export default {
  name: "Finish",
  props: ['currentUUID', 'legalDocument', 'flow', 'intent', 'language'],
  components: {EmailModal, ToolCards, BButton},
  data() {
    return {}
  },
  methods: {
    showEmailModal() {
      this.$refs.emailModal.show(this.currentUUID, this.intent)
    },
    applyScanResult() {
      this.$emit('applyScanResult')
    },
    close() {
      this.$emit('closeScanner')
    }
  }
}
</script>

<style scoped>
.info-text {
  color: #555555;
  font-size: 16px;
  line-height: 24px;
}

.inquiry-text {
  font-size: 24px;
  line-height: 33px;
}

.download-link-text {
  color: #BCBCBC;
  font-size: 16px;
  line-height: 19px;
}
</style>
