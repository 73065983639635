// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/img_privacy-policy_vectorjuice_freepik.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header[data-v-7a71287e]{padding:0 0 10px 0!important;color:#555;font-size:37px;font-weight:500;letter-spacing:0;line-height:51px}.info-text-container[data-v-7a71287e]{padding:0!important}.info-text[data-v-7a71287e]{color:#555;font-size:15px}.agreement-hint[data-v-7a71287e],.info-text[data-v-7a71287e]{letter-spacing:0;line-height:24px}.agreement-hint[data-v-7a71287e]{color:rgba(50,73,94,.35);font-size:16px}.privacy-policy-img[data-v-7a71287e]{height:auto;width:100%;max-height:270px;max-width:270px;min-width:250px;content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.img-container[data-v-7a71287e]{padding:0!important}", ""]);
// Exports
module.exports = exports;
