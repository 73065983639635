<template>
  <div class="main-container pb-5">
    <BRow class="d-flex justify-content-md-center text-center text-md-left pb-4">
      <BCol cols="12" class="header headline order-2 order-md-1">
        Erstellen Sie schnell und einfach eine <br> DSGVO-konforme Datenschutzerklärung
      </BCol>
      <BCol cols="12" md="7" class="info-text-container order-3 order-md-2">
        <div class="info-text">
          Sie können nun Ihre individuelle und DSGVO-konforme Datenschutzerklärung für Ihre Webseite erstellen.
          Der PRIVE Website-Scanner hat die gefundenen Plugins und Tools bereits für Sie vorausgewählt.
          Nachdem Sie den Generator vollständig durchlaufen haben, können Sie die Datenschutzerklärung in Textform,
          als HTML-Quellcode oder als PDF herunterladen.
          <div class="pt-4">
            <BButton class="text-uppercase text-white px-4"
                     :href="flow !== 'prive' ? '#scanner' : ''"
                     pill
                     style="padding-top: 10px"
                     @click="nextStep"
                     variant="secondary">
              Jetzt Datenschutzerklärung konfigurieren
            </BButton>
          </div>
          <div v-if="flow === 'prive' && intent !== 'dse'" class="pt-3">
            <BButton class="text-uppercase"
                     variant="link"
                     style="color: #BCBCBC"
                     @click="skip">
              <div class="text-uppercase row justify-content-center align-items-center">
                <div class="pt-1 pr-2">
                  Überspringen
                </div>
                <div>
                  <img src="../assets/icons/ic_arrow_right.svg" height="13" alt="">
                </div>
              </div>
            </BButton>
          </div>
          <div class="agreement-hint pt-3">
            Datenschutzhinweis: Ihre Daten werden {{ language === 'CH' ? 'ausschliesslich' : 'ausschließlich' }}
            zur Erzeugung Ihrer <br>
            Datenschtzerklärung genutzt. Die Daten werden nicht an Dritte weitergegeben.
          </div>
        </div>
      </BCol>
      <BCol cols="12" md="5" class="img-container d-flex justify-content-center order-1 order-md-3">
        <div class="privacy-policy-img"/>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";

export default {
  name: "PrivacyAgreementInfo",
  components: {BButton, BRow, BCol},
  props: ['intent', 'flow', 'language'],
  methods: {
    nextStep() {
      this.$emit('nextStep')
    },
    skip() {
      this.$emit('skipGenerator')
    }
  },
}
</script>

<style scoped>

.header {
  padding: 0px 0px 10px 0px!important;
  color: #555555;
  font-size: 37px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 51px;
}

.info-text-container {
  padding: 0px!important;
}

.info-text {
  color: #555555;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.agreement-hint {
  color: rgba(50, 73, 94, 0.35);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.privacy-policy-img {
  height: auto;
  width: 100%;
  max-height: 270px;
  max-width: 270px;
  min-width: 250px;
  content: url('../assets/images/img_privacy-policy_vectorjuice_freepik.svg');
}

.img-container {
  padding: 0px!important;
}

</style>
