<template>
  <div>
    <div class="row">

      <div class="col-12">
        <div class="row justify-content-center">

          <div class="pr-1"
               :class="visited(1) ? 'cursor-pointer' : ''"
               @click="setStep(1)">
            <img v-if="isInStep(1)"
                 src="../assets/icons/ic_progress_scanner_active.svg"
                 height="50"
                 alt="">
            <img v-else-if="!isInStep(1) && visited(1)"
                 src="../assets/icons/ic_progress_scanner_activated.svg"
                 height="50"
                 alt="">
          </div>

          <!--Separator-->
          <div class="d-flex justify-content-center align-items-center separator-container">
            <div class="separator"></div>
            <img v-if="visited(2)"
                 :class="visited(2) ? 'cursor-pointer' : ''"
                 @click="setStep(2)"
                 src="../assets/icons/ic_check_green.svg"
                 height="20"
                 alt="">
            <div class="separator"></div>
          </div>

          <div class="px-1"
               :class="visited(3) ? 'cursor-pointer' : ''"
               @click="setStep(3)">
            <img v-if="isInStep(3)"
                 src="../assets/icons/ic_progress_dse_konfigurator_active.svg"
                 height="50"
                 alt="">
            <img v-else-if="visited(3)"
                 src="../assets/icons/ic_progress_dse_konfigurator_activated.svg"
                 height="50"
                 alt="">
            <img v-else
                 src="../assets/icons/ic_progress_dse_konfigurator_inactive.svg"
                 height="50"
                 alt="">
          </div>

          <!--Separator-->
          <div class="d-flex justify-content-center align-items-center separator-container">
            <div class="separator"></div>
            <img v-if="visited(4)"
                 src="../assets/icons/ic_check_green.svg"
                 height="20"
                 alt="">
            <div class="separator"></div>
          </div>

          <div class="pl-1"
               :class="visited(4) ? 'cursor-pointer' : ''"
               @click="setStep(4)">
            <img v-if="isInStep(4)"
                 src="../assets/icons/ic_progress_abgeschlossen_active.svg"
                 height="50"
                 alt="">
            <img v-else-if="visited(4)"
                 src="../assets/icons/ic_progress_abgeschlossen_activated.svg"
                 height="50"
                 alt="">
            <img v-else
                 src="../assets/icons/ic_progress_abgeschlossen_inactive.svg"
                 height="50"
                 alt="">
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="row justify-content-center pt-2 stepper-info-text-container">
          <div :class="`stepper-text `+ `${isInStep(1) || visited(1) ? 'stepper-text-active': ''}`">Webseite <br> scannen</div>
          <div class="px-3">
            <div :class="`stepper-text `+ `${isInStep(3) || visited(3) ? 'stepper-text-active': ''}`">Datenschutz <br> konfigurieren</div>
          </div>
          <div :class="`stepper-text `+ `${isInStep(4) || visited(4) ? 'stepper-text-active': ''}`">Fertig</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",
  props: ['progress'],
  methods: {
    isInStep(step) {
      return this.progress.currentStep === step
    },
    visited(step) {
      return this.progress.progressStep >= step
    },
    setStep(step) {
      this.$emit('setStep', step)
    }
  },
}
</script>

<style scoped lang="scss">

@media only screen and (max-width: 400px) {
  .separator-container {
    width: 45px!important;
  }

  .stepper-info-text-container {
    display: none;
  }
}

.container {
  padding: 20px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #D9D9D9;
}

.stepper-text-active {
  color: #4EAACA!important;
}

.separator-container {
  width: 60px;
}

.stepper-text {
  height: 38px;
  width: 100px;
  color: #B6B6B6;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}

</style>
