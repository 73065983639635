import { render, staticRenderFns } from "./ConfigurationPage.vue?vue&type=template&id=95db4616&scoped=true&"
import script from "./ConfigurationPage.vue?vue&type=script&lang=js&"
export * from "./ConfigurationPage.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ConfigurationPage.vue?vue&type=style&index=0&id=95db4616&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "95db4616",
  null
  ,true
)

export default component.exports