<template>
  <div class="row justify-content-center">
    <div v-for="(tool, toolName) in toolsTemplates" :key="tool.name" class="py-2 px-2">

      <BCard no-body
             class="tool-card text-center py-4 px-2"
             :class="{
                      'tool-card-finish': isLastStep && isCompleted(toolName),
                      'cursor-pointer': isToolCardClickable(toolName)
             }"
             @click="isToolCardClickable(toolName) && $emit('showEmailModal')">
        <div>
          <img v-if="isLastStep && isCompleted(toolName)" :src="require(`../assets/icons/${tool.icon}_white.svg`)" height="47">
          <img v-else :src="require(`../assets/icons/${tool.icon}.svg`)" height="47">
        </div>
        <div class="pt-3 tool-card-headline">
          {{ tool.name }}
        </div>
        <div v-if="loading" class="pt-2">
          <BSpinner variant="secondary"
                    class="custom-spinner"></BSpinner>
        </div>
        <div v-if="!loading">
          <!-- Scanner Page -->
          <div v-if="!isLastStep"
               class="row justify-content-between align-items-center px-3 pt-2">
            <img v-if="getTools(toolName).length" src="../assets/icons/ic_check_gradient.svg" height="15">
            <img v-if="!getTools(toolName).length" src="../assets/icons/ic_x.svg" height="15">
            <div style="font-size: 15px !important">
                    {{formatResult(toolName)}}
            </div>

            <div v-if="!getTools(toolName).length" style="width: 15px"></div>
            <Popover v-if="getTools(toolName).length">
              <template v-slot:popover-trigger-element>
                <img src="../assets/icons/ic_info.svg" height="15">
              </template>

              <template v-slot:popover-content>
                <div class="p-2 text-center">
                  <div class="pb-2 pt-1 font-weight-normal"
                       style="font-size: 15px">
                    {{formatResult(toolName)}}
                  </div>
                  <div class="pb-1 font-weight-light">
                    <div v-for="(tool, index) in firstThreeTools(getTools(toolName))"
                         :key="`tool-${index}`">
                      {{ tool }}
                    </div>
                  </div>
                  <div v-if="getTools(toolName).length > 3" class="tool-card-placeholder">
                    <span style="position: relative; top: -8px; opacity: 0.5">....</span>
                  </div>
                  <div v-if="getTools(toolName).length > 3"
                       class="py-1 font-weight-lighter"
                       style="font-size: 12px; margin: auto">
                    Aus Platzgründen sehen Sie hier zunächst nur eine Auswahl der gefundenen Daten.
                  </div>
                </div>
              </template>
            </Popover>

          </div>

          <!-- Finish Page -->
          <div v-if="isLastStep" class="d-flex justify-content-center align-items-center pt-2">
            <img v-if="isCompleted(toolName)" src="../assets/icons/ic_check_white.svg" height="15">
            <img v-else src="../assets/icons/ic_x_red.svg" height="15" class="mt-1">
            <div class="px-3" style="font-size: 15px !important">
                    {{ isCompleted(toolName) ? 'Vorbereitet' : 'nicht konfiguriert' }}
            </div>
            <div v-if="!getTools(toolName).length" style="width: 15px"></div>
            <Popover v-if="getTools(toolName).length">
              <template v-slot:popover-trigger-element>
                <img v-if="isCompleted(toolName)" src="../assets/icons/ic_info_white.svg" height="15">
                <img v-else src="../assets/icons/ic_info.svg" height="15">
              </template>

              <template v-slot:popover-content>
                <div class="p-2 text-center">
                  <div class="pb-2 pt-1 font-weight-normal"
                       style="font-size: 15px !important;">
                    {{ getTools(toolName).length }} Tools gefunden
                  </div>
                  <div class="pb-1 font-weight-light">
                    <div v-for="(tool, index) in getTools(toolName)"
                         :key="`tool-${index}`">
                      {{ tool }}
                    </div>
                  </div>
                  <div v-if="getTools(toolName).length > 3">
                    <div class="tool-card-placeholder">
                      <span style="position: relative; top: -8px; opacity: 0.5">....</span>
                    </div>
                    <div class="py-1 font-weight-lighter" style="font-size: 12px; margin: auto">
                      Aus Platzgründen sehen Sie hier zunächst nur eine Auswahl der gefundenen Daten.
                    </div>
                  </div>
                </div>
              </template>
            </Popover>
          </div>

          <!-- Extra-Sections -->
          <div v-if="!isLastStep">
            <div v-if="!isDSECard(toolName) && !isExternalConnectionsCard(toolName)" style="height: 23px"/>

            <!-- Link-Section For DSE Card -->
            <div v-if="isDSECard(toolName)" class="row justify-content-between align-items-center px-3">
              <img v-if="linkCount === 2" src="../assets/icons/ic_check_gradient.svg" height="15">
              <img v-if="linkCount !== 2" src="../assets/icons/ic_x.svg" height="15">
              <div>{{linkCount}} {{ linkCount !== 1 ? 'Links' : 'Link' }} gefunden</div>
              <Popover>
                <template v-slot:popover-trigger-element>
                  <img src="../assets/icons/ic_info.svg" height="15">
                </template>

                <template v-slot:popover-content>
                  <div class="p-3 text-center">
                    <div class="row flex-nowrap align-items-center">
                      <img v-if="tools.privacyAgreementLinkFound" src="../assets/icons/ic_check_gradient.svg" height="15">
                      <img v-if="!tools.privacyAgreementLinkFound" src="../assets/icons/ic_x.svg" height="15">
                      <div class="pl-2 text-nowrap">
                        {{ tools.privacyAgreementLinkFound ? '' : 'Kein' }} Datenschutz gefunden
                      </div>
                    </div>
                    <div class="row flex-nowrap align-items-center pt-1">
                      <img v-if="tools.impressum" src="../assets/icons/ic_check_gradient.svg" height="15">
                      <img v-if="!tools.impressum" src="../assets/icons/ic_x.svg" height="15">
                      <div class="pl-2 text-nowrap">
                        {{ tools.impressum ? '' : 'Kein' }} Impressum gefunden
                      </div>
                    </div>
                  </div>
                </template>
              </Popover>
            </div>

            <!-- Cookie-Section For ExternalConnections Card -->
            <div v-if="isExternalConnectionsCard(toolName)" class="row justify-content-between align-items-center px-3">
              <img v-if="cookiesCount" src="../assets/icons/ic_check_gradient.svg" height="15">
              <img v-if="!cookiesCount" src="../assets/icons/ic_x.svg" height="15">

              <div style="font-size: 15px">{{ cookiesCount }} {{ cookiesCount !== 1 ? 'Cookies' : 'Cookie' }} gefunden</div>
              <div v-if="!cookiesCount" style="width: 15px"></div>

              <Popover v-if="cookiesCount">
                <template v-slot:popover-trigger-element>
                  <img src="../assets/icons/ic_info.svg" height="15">
                </template>

                <template v-slot:popover-content>
                  <div class="p-2 text-center">
                    <div class="pb-2 pt-1 font-weight-normal"
                         style="font-size: 15px !important;">
                      Gesetzte Third-Party-Cookies
                    </div>
                    <div class="pb-1 font-weight-light">
                      <div v-for="(cookie, index) in firstThreeTools(tools.externalCookieDomains)" :key="index">
                        {{ cookie }}
                      </div>
                    </div>

                    <div v-if="cookiesCount > 3" >
                      <div class="tool-card-placeholder">
                        <span style="position: relative; top: -8px; opacity: 0.5">....</span>
                      </div>
                      <div class="py-1 font-weight-lighter" style="font-size: 12px; margin: auto">
                        Aus Platzgründen sehen Sie hier zunächst nur eine Auswahl der gefundenen Daten.
                      </div>
                    </div>
                  </div>
                </template>
              </Popover>
            </div>
          </div>
        </div>
        <!-- Bottom info Text-->
        <div class="pt-3 tool-card-sub-text row justify-content-center"
             :class="isCompleted(toolName) && isLastStep ? 'text-white' : ''">
          {{ tool.infoText }}
        </div>
      </BCard>

    </div>
  </div>
</template>

<script>
import {BCard, BSpinner} from "bootstrap-vue";
import Popover from "@/legal-website-scanner/components/Popover";

export default {
  name: "ToolCards",
  props: ['tools', 'loading', 'isLastStep', 'flow', 'hasDSE', 'intent', 'language'],
  components: {Popover, BCard, BSpinner},
  data() {
    return {
      templates: {
        consent: {
          name: 'Cookie Consent',
          type: 'consent',
          content: [],
          icon: 'ic_cookies',
          infoText: 'Für bestimmte Tools und Plugins ist eine Einwilligung Ihres Webseiten-besuchers erforderlich. Die gefundenen Technologien sollten mit einem Cookie Consent Tool abgefragt werden.'
        },
        externalURLs: {
          name: 'Externe Verbindungen',
          type: 'externalURLs',
          content: [],
          icon: 'ic_external',
          infoText: 'Bitte beachten Sie, dass ausgehende Verbindungen und Datentransfers wie Marketing-Pixel, Third-Party-Cookies etc. ohne vorherige Einwilligung des Nutzers in der Regel unzulässig sind.'
        },
        processingActivity: {
          name: 'Datenschutzerklärung',
          type: 'processingActivity',
          content: [],
          icon: 'ic_dse',
          infoText: 'Sie müssen in Ihrer Datenschutzerklärung über sämtliche Technologien auf Ihrer Webseite informieren. Die gefundenen Technologien werden direkt an den PRIVE-Generator übergeben.'
        },
        tom: {
          name: 'TOMs',
          type: 'tom',
          content: [],
          icon: 'ic_toms',
          infoText: `Technische und organisatorische ${this.language === 'CH' ? 'Massnahmen' : 'Maßnahmen'} (TOMs) zum Schutz personenbezogener Daten müssen protokolliert werden. Die gefundenen TOMs können direkt in PRIVE übernommen werden.`
        },
        dataProcessingAgreement: {
          name: 'Verarbeitungsverzeichnis',
          type: 'dataProcessingAgreement',
          content: [],
          icon: 'ic_vvs',
          infoText: 'Ein Verarbeitungsverzeichnis dient der Erfassung aller Daten-verarbeitungsvorgänge in Ihrem Unternehmen. Die gefundenen Tätigkeiten können direkt in die PRIVE übernommen werden.'
        }
      }
    }
  },
  computed: {
    toolsTemplates() {
      let templates = {}

      Object.keys(this.templates).forEach(key => {
        if (key === 'externalURLs') {
          if (this.intent === 'itrk') {
            templates[key] = this.templates[key]
          }
        } else if (key === 'processingActivity') {
          if (this.intent !== 'itrk') {
            templates[key] = this.templates[key]
          }
        } else {
          templates[key] = this.templates[key]
        }
      })

      if (this.tools) {
        Object.keys(this.tools).forEach(key => {
          if (key in templates) {
            let value = this.tools[key]
            templates[key].content = value
          }
        })
      }
      return templates
    },
    linkCount() {
      let linkCount = 0
      if (this.tools) {
        if (this.tools.privacyAgreementLinkFound) {
          linkCount++
        }
        if (this.tools.impressum) {
          linkCount++
        }
      }
      return linkCount
    },
    cookiesCount() {
      return this.tools.externalCookieDomains.length
    }
  },
  methods: {
    isCompleted(toolName) {
      if (this.intent === 'report' || this.intent === 'itrk') {
        return true
      }
      if (this.flow === 'prive') {
        return !this.isDSECard(toolName) || (this.hasDSE && this.isDSECard(toolName))
      } else {
        return this.isDSECard(toolName)
      }
    },
    isToolCardClickable(toolName) {
      if (this.intent === 'report') {
        return true
      }
      return this.isLastStep && this.flow !== 'prive' && this.isDSECard(toolName)
    },
    getTools(toolName) {
      return this.toolsTemplates[toolName].content
    },
    formatResult(toolName) {
      let amount = this.getTools(toolName).length
      let name
      switch (toolName) {
        case 'tom':
          name = amount === 1 ? ' Tom' : ' Toms'
          break
        case 'externalURLs':
          name = ' Aktive'
          break
        case 'dataProcessingAgreement':
          name = amount === 1 ? ' VV' : ' VVs'
          break
        default:
          name = ' Tool'
          name = amount === 1 ? ' Tool' : ' Tools'
      }
      return amount + name + ' gefunden'
    },
    firstThreeTools(tools) {
      let firstThree = []
      if (tools) {
        firstThree = tools.slice(0, 3);
      }
      return firstThree
    },
    isDSECard(toolName) {
      return this.toolsTemplates[toolName].name === 'Datenschutzerklärung'
    },
    isExternalConnectionsCard(toolName) {
      return this.toolsTemplates[toolName].name === 'Externe Verbindungen'
    },
  },
}
</script>

<style scoped lang="scss">
.tool-card {
  border-radius: 10px;
  background: #FAFCFF;
  border: 1px solid #F1F1F1;
  box-shadow: none;
  width: 230px;
  min-height: 310px;
}

.tool-card-headline {
  font-family: UniviaPro-Bold;
  font-size: 16px !important;
}

.tool-card-sub-text {
  max-width: 230px;
  font-size: 12px;
  color: #AFAFAF;
  line-height: 17px;
}

.tool-card-placeholder {
  margin: auto;
  border-radius: 8px;
  background-color: #F1F1F1;
  width: 30px;
  height: 15px;
}

.tool-card-finish {
  color: white;
  background: linear-gradient(to right, #50ADC7, #69C2A5);
}
</style>
