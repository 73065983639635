function getFromStorage(propName) {
    return JSON.parse(localStorage.getItem(propName))
}

function initLegalWebsiteScannerStore() {
    localStorage.setItem('legalWebsiteScannerStore', JSON.stringify({}))
}

function setInLegalWebsiteScannerStore(propName, prop) {
    let legalWebsiteScannerStore = getFromStorage('legalWebsiteScannerStore')

    if (!legalWebsiteScannerStore) {
        initLegalWebsiteScannerStore()
        legalWebsiteScannerStore = getFromStorage('legalWebsiteScannerStore')
    }

    legalWebsiteScannerStore[propName] = prop
    localStorage.setItem('legalWebsiteScannerStore', JSON.stringify(legalWebsiteScannerStore));
}

function getFromLegalWebsiteScannerStore(propName) {
    let legalWebsiteScannerStore = getFromStorage('legalWebsiteScannerStore')

    if (legalWebsiteScannerStore) {
        return legalWebsiteScannerStore[propName]
    } else {
        return null
    }
}

module.exports = {
    setInLegalWebsiteScannerStore,
    getFromLegalWebsiteScannerStore,
    initLegalWebsiteScannerStore
}
