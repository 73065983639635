import axios from 'axios'

const AXIOS = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        Accept: 'application/json',
        ContentType: 'application/json'
    }
})

export default {

    scanWebsite(websiteScanForm, success, failure) {
        AXIOS.post('/api/wc/website-scanner/scan',websiteScanForm)
            .then(success)
            .catch(failure)
    },

    websiteScan(websiteScanUUID, success, failure) {
        AXIOS.get(`/api/wc/website-scanner/scan/${websiteScanUUID}`)
            .then(success)
            .catch(failure)
    },
    websiteScanReportHTML(websiteScanUUID, success, failure) {
        AXIOS.get(`/api/wc/website-scanner/render/${websiteScanUUID}/html`)
            .then(success)
            .catch(failure)
    },

    legalDocumentDefinition(documentDefinitionId, success, failure) {
        AXIOS.get(`api/cockpit/resources/legaldocument/definitions/${documentDefinitionId}/groups`)
            .then(success)
            .catch(failure)
    },
    sendLegalDocument(documentDefinitionId, legalDocForm, success, failure) {
        AXIOS.post(`/api/wc/legal-document/form/${documentDefinitionId}`, legalDocForm)
            .then(success)
            .catch(failure)
    },
    sendMail(legalDocFormMail, success, failure) {
        AXIOS.post('/api/wc/legal-document/form/mail', legalDocFormMail)
            .then(success)
            .catch(failure)
    },

    legalDocument(websiteScanUUID, success, failure) {
        AXIOS.get(`/api/wc/website-scanner/legal-document/${websiteScanUUID}`)
        .then(success)
        .catch(failure)
    },
    legalDocumentHTML(documentId, success, failure) {
        AXIOS.get(`/api/cockpit/resources/legaldocument/${documentId}/render/html`, {
            headers: {
                Accept: 'text/html',
                ContentType: 'text/html'
            }
        })
            .then(success)
            .catch(failure)
    },
}
