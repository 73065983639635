<template>
  <div>
    <div v-if="loading" class="py-5 row justify-content-center">
      <BSpinner variant="secondary"
                class="custom-spinner"></BSpinner>
    </div>

    <BAlert :show="!loading && !legalDocuments.length"
            variant="danger"
            class="m-0 mt-2 py-2">
      <div class="text-center">
            Es ist ein Fehler aufgetreten!
      </div>
    </BAlert>

    <div v-if="!loading && legalDocuments.length" class="row flex-nowrap">
      <div class="col">
        <BTabs pills vertical v-model="currentTabIndex" class="custom-vertical-tab-bar" ref="vertical_tab_bar">
          <!-- Main-Content-Container -->
          <div class="main-content-container">
            <BTab v-for="(legalDocument, tabIndex) in legalDocuments"
                  lazy
                  :key="legalDocument.id"
                  :id="'legal_doc_tab_' + legalDocument.id"
                  class="custom-scrollbar"
                  @click="handleTabClick(tabIndex)">
              <!-- Title -->
              <template #title>
                <div class="row flex-nowrap align-items-center"
                     :class="isDocumentDone(legalDocument) ? 'tab-done' : 'tab-not-done'">

                  <div>{{ legalDocument.title }}</div>
<!--                  <div v-if="unfilledMandatoryError && hasUnfilledMandatoryGroups(legalDocument)"-->
<!--                       class="col text-danger text-right font-weight-bold">-->
<!--                    ⓘ-->
<!--                  </div>-->
                </div>
              </template>

              <div class="col-12 pt-3 px-4">
                <!-- Title And Info-Container -->
                <div class="">
                  <div class="text-primary title">{{ legalDocument.title }}</div>
                  <div class="pt-3 subtitle">{{ legalDocument.infoText }}</div>
                </div>
                <!-- Chips-Container -->
                <div class="row px-0 pt-4">

<!--                  <div v-if="hasMandatoryGroups(legalDocument)"-->
<!--                       class="col-12 pb-4 row align-items-center">-->
<!--                    <div class="mandatory-circle-icon"></div>-->
<!--                    <div class="pl-1" style="font-size: 12px">Pflichtangaben</div>-->
<!--                  </div>-->
                  <!-- Chip -->
                  <div v-for="definitionGroup in legalDocument.inputDefinitionGroups"
                       :key="definitionGroup.id"
                       @click="handleChipClick(definitionGroup)"
                       class="row flex-nowrap p-0 mr-2 mb-2 tool-chip"
                       :class="chipClassObject(definitionGroup)"
                  >
                    <div class="px-3 py-2">
                      {{ definitionGroup.title }}
                    </div>

                    <div v-if="isEnableableGroup(definitionGroup)"
                         @click="enable(definitionGroup)"
                         class="checkmark-container px-3 row justify-content-center align-items-center">
                      <img height="16"
                           :src="isGroupDone(definitionGroup)
                             ? require('../assets/icons/ic_check_white.svg')
                             : require('../assets/icons/ic_check_grey.svg')">
                    </div>
                  </div>
                </div>
              </div>
            </BTab>
          </div>
          <!-- Action-Buttons-Container -->
          <div class="btn-container col-12 px-4 row justify-content-center align-items-center">
            <BButton v-if="currentTabIndex < legalDocuments.length - 1"
                     @click="handleNextClick()"
                     variant="primary"
                     pill
                     class="px-4 text-uppercase">
              Weiter
            </BButton>

            <div v-if="currentTabIndex === legalDocuments.length - 1"
                 class="row justify-content-center">
              <BButton @click="done"
                       variant="primary"
                       pill
                       class="text-uppercase"
                       :href="flow !== 'prive' ? '#scanner' : ''">
                Abschliessen
              </BButton>
            </div>
          </div>
        </BTabs>
      </div>
      <!-- InputDefinitionEditor-Container -->
      <!-- FIXME: Remove false in showUnfilledErrorAlert-Prop (only for now to disable error)-->
      <div class="">
        <InputDefinitionEditor :definitionGroup="selectedDefinitionGroup"
                               :formInputJson="formInputJson"
                               :showUnfilledErrorAlert="false && unfilledMandatoryError && hasUnfilledMandatories()"
                               @updatedValue="updateFormInputJson"/>
      </div>
    </div>
  </div>
</template>

<script>
import {BAlert, BButton, BSpinner, BTab, BTabs,} from 'bootstrap-vue'
import Api from "@/legal-website-scanner/services/Api";
import storageUtils from "@/legal-website-scanner/services/storageUtils";
import InputDefinitionEditor from "@/legal-website-scanner/components/InputDefinitionEditor";

export default {
  name: "ConfigurationPage",
  props: ['legalDocumentDefinitionId', 'legalDocument', 'toolNames', 'flow', 'intent', 'isNewDse'],
  components: {InputDefinitionEditor, BTabs, BTab, BButton, BSpinner, BAlert},
  data() {
    return {
      unfilledMandatoryError: false,
      currentTabIndex: 0,
      loading: false,
      legalDocuments: [],
      selectedDefinitionGroup: null,
      formInputJson: {},
    }
  },
  mounted() {
    this.loading = true
    Api.legalDocumentDefinition(this.legalDocumentDefinitionId, response => {
      this.legalDocuments = response.data
      this.init()
      this.loading = false
    }, error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    scrollToDocumentTabByDocumentId(id) {
      // TODO: find working alternative to this shit
      if (!this.$refs.vertical_tab_bar) {
        return
      }
      let bootstrapTabElementId = `legal_doc_tab_${id}___BV_tab_button__`
      let tabBar = this.$refs.vertical_tab_bar.$el.children[0].firstChild

      for (let tabContainer of tabBar.children) {
        let tab = tabContainer.children[0]
        if (tab.id === bootstrapTabElementId) {
          tabBar.scrollTop = tab.offsetTop
          this.currentTabIndex = Number(tab.getAttribute('aria-posinset') - 1)
          break
        }
      }
    },
    scrollToDocumentTabByTabIndex(tabIndex) {
      // TODO: find working alternative to this shit
      if (!this.$refs.vertical_tab_bar) {
        return
      }
      let tabBar = this.$refs.vertical_tab_bar.$el.children[0].firstChild
      if (tabBar) {
        for (let tabContainer of tabBar.children) {
          let tab = tabContainer.children[0]
          if (tab.getAttribute('aria-posinset') == tabIndex) {
            tabBar.scrollTop = tab.offsetTop
            break
          }
        }
      }
    },
    init() {
      this.restoreTabIndexFromStorage()
      this.scrollToDocumentTabByTabIndex(this.currentTabIndex)
      if (!this.isNewDse) {
        this.restoreFormInputJson()
      }
      if (!Object.keys(this.formInputJson).length) {
        this.setPreSelectedInputDefinitions()
      }
    },
    updateFormInputJson(data) {
      if (data.value) {
        this.$set(this.formInputJson, data.title, data.value)
      } else {
        this.$delete(this.formInputJson, data.title)
      }
      this.storeFormInputJsonInStorage()
    },
    enable(inputDefinitionGroup) {
      let enableableInput = inputDefinitionGroup.inputDefinitions.find(inputDefinition => inputDefinition.inputType === 'GroupEnabled')
      if (enableableInput) {
        let value = !(enableableInput.title in this.formInputJson)
        let inputDefinition = {
          title: enableableInput.title,
          value: value
        }
        this.updateFormInputJson(inputDefinition)
      }
    },
    setPreSelectedInputDefinitions() {
      this.legalDocuments.forEach(legalDocument => {
        legalDocument.inputDefinitionGroups.forEach(inputDefinitionGroup => {
          if (this.toolNames.includes(inputDefinitionGroup.title)) {
            inputDefinitionGroup.inputDefinitions.forEach(inputDefinition => {
              if (inputDefinition.inputType === 'GroupEnabled') {
                this.updateFormInputJson({title: inputDefinition.title, value: true})
              }
            })
          }
        })
      })
    },
    done() {
      this.$emit('configurationDone', this.formInputJson)
    },
    getFirstUnfilledMandatory() {
      return this.legalDocuments.find(this.hasUnfilledMandatoryGroups)
    },
    hasUnfilledMandatories() {
      return !!this.getFirstUnfilledMandatory()
    },
    hasMandatoryGroups(legalDocument) {
      return !!legalDocument.inputDefinitionGroups.find(this.isMandatory)
    },
    hasUnfilledMandatoryGroups(legalDocument) {
      return !!legalDocument.inputDefinitionGroups.find(this.hasUnfilledMandatoryInputDefinitions)
    },
    hasUnfilledMandatoryInputDefinitions(inputDefinitionGroup) {
      return !!inputDefinitionGroup.inputDefinitions.find(inputDefinition => inputDefinition.mandatory && !(inputDefinition.title in this.formInputJson))
    },
    isMandatory(inputDefinitionGroup) {
      return !!inputDefinitionGroup.inputDefinitions.find(inputDefinition => inputDefinition.mandatory)
    },
    isSelected(inputDefinitionGroup) {
      return inputDefinitionGroup === this.selectedDefinitionGroup
    },
    getEnableableInputDefinition(inputDefinitionGroup) {
      return inputDefinitionGroup.inputDefinitions.find(inputDefinition => inputDefinition.inputType === 'GroupEnabled')
    },
    isEnableableGroup(inputDefinitionGroup) {
      return !!this.getEnableableInputDefinition(inputDefinitionGroup)
    },
    isDocumentDone(legalDocument) {
      return !!legalDocument.inputDefinitionGroups.find(this.isGroupDone)
    },
    isGroupDone(inputDefinitionGroup) {
      if (!inputDefinitionGroup) {
        return false
      }
      // is GroupEnabled
      let enableableInput = this.getEnableableInputDefinition(inputDefinitionGroup)
      if (enableableInput) {
        return (
            // check if GroupEnabled has been selected
            enableableInput.title in this.formInputJson
            // &&
            // // check if all mandatory fields have values
            // !this.hasUnfilledMandatoryInputDefinitions(inputDefinitionGroup)
        )
      }
      return (
          // check if any input has a value
          !!inputDefinitionGroup.inputDefinitions.find(inputDefinition => inputDefinition.title in this.formInputJson)
          // &&
          // // check if all mandatory fields have values
          // !this.hasUnfilledMandatoryInputDefinitions(inputDefinitionGroup)
      )
    },
    handleChipClick(definitionGroup) {
      this.selectedDefinitionGroup = definitionGroup
    },
    handleTabClick(tabIndex) {
      this.selectedDefinitionGroup = null
      this.currentTabIndex = tabIndex
      this.storeTabIndexInStorage()
    },
    handleNextClick() {
      this.selectedDefinitionGroup = null
      this.currentTabIndex++
      this.storeTabIndexInStorage()
      this.scrollToDocumentTabByTabIndex(this.currentTabIndex)
    },
    chipClassObject(inputDefinitionGroup) {
      return {
        done: this.isGroupDone(inputDefinitionGroup),
        selected: this.isSelected(inputDefinitionGroup),
        // mandatory: this.isMandatory(inputDefinitionGroup),
        // mandatorySelected: this.isMandatory(inputDefinitionGroup) && this.isSelected(inputDefinitionGroup),
        // mandatorySelectedDone: this.isMandatory(inputDefinitionGroup) && this.isSelected(inputDefinitionGroup) && this.isDone(inputDefinitionGroup),
      }
    },
    storeTabIndexInStorage() {
      storageUtils.setInLegalWebsiteScannerStore('currentTabIndex', this.currentTabIndex)
    },
    restoreTabIndexFromStorage() {
      this.currentTabIndex = storageUtils.getFromLegalWebsiteScannerStore('currentTabIndex') || this.currentTabIndex
    },
    storeFormInputJsonInStorage() {
      storageUtils.setInLegalWebsiteScannerStore('formInputJson', this.formInputJson)
    },
    restoreFormInputJsonFromLegalDocument() {
      if (this.legalDocument) {
        return JSON.parse(this.legalDocument.formInputJson)
      }
    },
    restoreFormInputJson() {
      // prioritize formInputJson stored in localStorage
      let storedFormInputJson = storageUtils.getFromLegalWebsiteScannerStore('formInputJson')

      if (!storedFormInputJson || !Object.keys(storedFormInputJson).length) {
        storedFormInputJson = this.restoreFormInputJsonFromLegalDocument()
      }

      if (storedFormInputJson) {
        for (const keyValuePair of Object.entries(storedFormInputJson)) {
          const title = keyValuePair[0]
          const value = keyValuePair[1]

          let inputDefinition = {
            title: title,
            value: value
          }
          this.updateFormInputJson(inputDefinition)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

//.tab-done {
//  color: $white;
//  background: $success;
//}
//.tab-done.active {
//  color: red;
//  background: $success;
//}

.mandatory-circle-icon {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: $dark-gray;
}

.title {
  font-size: 20px;
}

.subtitle {
  font-size: 14px;
  color: $dark-gray;
}

.tool-chip {
  border: 1px solid $light-gray;
  background: $white;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(164, 164, 164, 0.26);
  .checkmark-container {
    border-left: 1px solid $light-gray;
    border-radius: 0px 4px 4px 0px;
  }
  .checkmark-container:hover {
    background: darken($white, 4%);
  }
}

.tool-chip:hover {
  border: 1px solid $tertiary;
  background: darken($white, 2%);
  .checkmark-container {
    border-left: 1px solid $tertiary;
  }
  .checkmark-container:hover {
    background: darken($white, 6%);
  }
}

.selected {
  border: 1px solid $tertiary;
  background: $white;
  .checkmark-container {
    border-left: 1px solid $tertiary;
  }
  .checkmark-container:hover {
    background: darken($white, 4%);
  }
}

.mandatory {
  border: 1px solid $dark-gray;
  background: $dark-gray;
  color: $white;
  .checkmark-container {
    border-left: 1px solid $light-gray;
  }
}

.mandatory:hover {
  border: 1px solid $secondary;
  background: darken($dark-gray, 5%);
  color: $white;
  .checkmark-container {
    border-left: 1px solid $light-gray;
  }
}

.mandatorySelected {
  border: 1px solid $secondary;
  background: darken($dark-gray, 5%);
  color: $white;
  .checkmark-container {
    border-left: 1px solid $light-gray;
  }
}

.done {
  border: 1px solid $tertiary;
  background: $tertiary;
  color: $white;
  .checkmark-container {
    border-left: 1px solid $white;
  }
  .checkmark-container:hover {
    background: darken($tertiary, 4%);
  }
}

.done:hover {
  border: 1px solid $tertiary;
  background: darken($tertiary, 5%);
  .checkmark-container {
    border-left: 1px solid $white;
  }
  .checkmark-container:hover {
    background: darken($tertiary, 9%);
  }
}
</style>
