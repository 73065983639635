<template>
  <div class="pa-4">

    <div v-if="loading" class="py-5 row justify-content-center">
      <BSpinner variant="secondary"
                class="custom-spinner"/>
    </div>

    <div v-if="!loading">

      <BAlert :show="error"
              variant="danger"
              class="m-0 mt-2 py-2">
        <div class="text-center">
          {{errorMessage}}
        </div>
      </BAlert>

      <div v-if="!error" class="px-0 px-md-5">

        <!-- Report HTML -->
        <div v-html="reportHTML" style="border: 1px solid #E0E0E0;">
        </div>

        <!-- Action Buttons -->
        <div class="row mt-5">

          <div class="col-12 row justify-content-center mt-4">
            <BButton class="py-2 px-4 text-white text-uppercase"
                     pill
                     @click="download">
              <img src="../assets/icons/ic_download.svg"
                   height="16"
                   alt="">
              <span class="pl-2">Als PDF herunterladen</span>
            </BButton>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton, BSpinner, BAlert} from "bootstrap-vue";
import Api from "@/legal-website-scanner/services/Api";

export default {
  name: 'ReportDownload',
  props: ['currentUUID'],
  components: {BButton, BSpinner, BAlert},
  data() {
    return {
      baseURL: process.env.VUE_APP_API_BASE_URL,
      loading: false,
      error: false,
      errorMessage: 'Es ist ein Fehler aufgetreten!',
      reportHTML: null
    }
  },
  mounted() {
    this.loading = true
    Api.websiteScanReportHTML(this.currentUUID, response => {
      this.reportHTML = response.data
      this.loading = false
      this.error = false
    }, error => {
      console.log(error)
      this.loading = false
      this.error = true
    })
  },
  methods: {
    download() {
      window.open(`${this.baseURL}/api/wc/website-scanner/render/${this.currentUUID}/pdf`, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/theme";
</style>
