<template>
  <div id="app">
    <div class="legal-website-scanner">
      <BCard no-body class="legal-website-scanner-card px-3 px-lg-4" :class="showStepper ? 'pt-4 pb-3': 'py-5'">
        <div v-if="loading" class="py-5 row justify-content-center">
          <BSpinner variant="secondary"
                    class="custom-spinner"></BSpinner>
        </div>
        <div v-if="!loading">
          <Stepper v-if="showStepper"
                   class="pt-0"
                   :progress="progress"
                   @setStep="setStep"/>

          <Scanner v-if="progress.currentStep === 1"
                   class="px-3 px-md-5 pt-3"
                   :currentUUID="currentUUID"
                   :flow="flow"
                   :intent="intent"
                   :origin="origin"
                   :tools=tools
                   :urlProp="url"
                   :screenshot="screenshot"
                   :scanAvailable="scanAvailable"
                   :language="language"
                   @handleWebsiteScan="handleWebsiteScan"
                   @nextStep="nextStepAfterScan"/>

          <PrivacyAgreementInfo v-if="progress.currentStep === 2"
                                :intent="intent"
                                :flow="flow"
                                :language="language"
                                class="px-4 px-md-5 pt-5"
                                @skipGenerator="handleSkipGenerator"
                                @nextStep="nextStep"/>

          <ConfigurationPage v-if="progress.currentStep === 3"
                             class="px-3 px-lg-3 pt-4"
                             :flow="flow"
                             :intent="intent"
                             :isNewDse="isNewDse"
                             :legalDocument="legalDocument"
                             :legalDocumentDefinitionId="legalDocumentDefinitionId"
                             :toolNames="toolNames"
                             @configurationDone="handleConfigurationDone"/>

          <Finish v-if="progress.currentStep === 4"
                  :currentUUID="currentUUID"
                  :flow="flow"
                  :intent="intent"
                  :legalDocument="legalDocument"
                  :language="language"
                  @applyScanResult="applyScanResult"
                  @closeScanner="closeScanner"
                  class="px-3 px-md-5"/>

          <PrivacyAgreementDownload v-if="progress.currentStep === 5"
                                    class="px-3 px-md-5 pt-3"
                                    :currentUUID="currentUUID"
                                    :intent="intent"
                                    :flow="flow"
                                    :language="language"
                                    @createNewPrivacyAgreement="startPrivacyAgreementGenerator"
                                    @closeScanner="closeScanner"/>
          <ReportDownload v-if="progress.currentStep === 6"
                          :currentUUID="currentUUID"/>
        </div>
      </BCard>
    </div>
  </div>
</template>

<script>
import storageUtils from "@/legal-website-scanner/services/storageUtils";
import {BCard, BSpinner} from "bootstrap-vue";
import Scanner from "@/legal-website-scanner/components/Scanner";
import Stepper from "@/legal-website-scanner/components/Stepper";
import ConfigurationPage from "@/legal-website-scanner/components/ConfigurationPage";
import PrivacyAgreementInfo from "@/legal-website-scanner/components/PrivacyAgreementInfo";
import Finish from "@/legal-website-scanner/components/Finish";
import PrivacyAgreementDownload from "@/legal-website-scanner/components/PrivacyAgreementDownload";
import Api from "@/legal-website-scanner/services/Api";
import ReportDownload from "@/legal-website-scanner/components/ReportDownload";

const dayInMilliseconds = 24 * 60 * 60 * 1000

export default {
  name: 'LegalWebsiteScanner',
  props: {
    uuidProp: {
      type: String,
      default: null,
    },
    defId: {
      type: String,
      default: 'Agency_DSE',
    },
    intentProp: {
      type: String,
      default: 'scan',
    },
    url: {
      type: String,
      default: null,
    },
    originProp: {
      type: String,
      default: null,
    },
    fresh: {
      type: Boolean,
      default: false,
    },
    flow: {
      type: String,
      default: 'prive-web',
    },
    language: {
      type: String,
      default: 'DE',
    },
  },
  components: {
    BCard,
    Scanner,
    Stepper,
    ConfigurationPage,
    PrivacyAgreementInfo,
    Finish,
    PrivacyAgreementDownload,
    ReportDownload,
    BSpinner
  },
  data() {
    return {
      intent: null,
      configurationInputJson: null,
      legalDocument: null,
      origin: null,
      websiteScan: null,
      legalDocumentDefinitionId: null,
      progress: {
        currentStep: 1,
        progressStep: 1
      },
      loading: false,
      currentUUID: null,
      isNewDse: false
    }
  },
  mounted() {
    if (this.fresh) {
      this.reset()
    }  else {
      this.restoreProgressFromStorage()
    }

    this.intent = this.intentProp
    this.origin = this.originProp || window.location.hostname
    this.legalDocumentDefinitionId = this.defId
    this.currentUUID = this.uuidProp || storageUtils.getFromLegalWebsiteScannerStore('currentUUID')

    const uuidUrlParam = new URLSearchParams(window.location.search).get('uuid')
    const documentParam = new URLSearchParams(window.location.search).get('document')

    if (uuidUrlParam) {
      this.currentUUID = uuidUrlParam
      if (documentParam === 'report') {
        this.goToReportDownloadPage()
      } else if (documentParam === 'dse') {
        this.goToLegalDocumentDownloadPage()
      }
    } else if (this.intent === 'dse') {
      this.startDseIntent()
    } else if (this.intent === 'report') {
      this.startReportIntent()
    } else if (this.intent === 'itrk') {
      this.startITRKIntent()
    } else {
      this.startScanIntent()
    }

  },
  computed: {
    showStepper() {
      return this.intent === 'scan' && this.progress.currentStep !== 5 && this.progress.currentStep !== 6
    },
    tools() {
      if (this.websiteScan && this.websiteScan.scanResultJson) {
        return JSON.parse(this.websiteScan.scanResultJson)
      }
      return null
    },
    toolNames() {
      let toolNames = []
      if (this.tools) {
        Object.keys(this.tools).forEach(key => {
          let value = this.tools[key]
          if (Array.isArray(value)) {
            toolNames.push(...value)
          }
        })
      }
      return toolNames
    },
    screenshot() {
      if (!this.websiteScan) {
        return null
      }
      return this.websiteScan.screenshot
    },
    scanAvailable() {
      if (this.flow === 'prive' && this.websiteScan && this.websiteScan.scanDate) {
        let today = new Date()
        let daysUntilNextScan = 10 * dayInMilliseconds
        let nextScanDate = new Date(this.websiteScan.scanDate + daysUntilNextScan)
        return today.getTime() >= nextScanDate.getTime()
      }
      return true
    }
  },
  methods: {
    reset() {
      storageUtils.initLegalWebsiteScannerStore()
      this.intent = null
      this.legalDocument = null
      this.configurationInputJson = null
      this.progress = {
        currentStep: 1,
        progressStep: 1
      }
    },
    init() {
      if (!this.currentUUID) {
        return
      }
      this.loading = true
      Api.websiteScan(this.currentUUID, response => {
        this.websiteScan = response.data
        Api.legalDocument(this.currentUUID, response => {
          this.legalDocument = response.data
          this.loading = false
        }, error => {
          console.log(error)
          this.loading = false
        })
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    // Start different intents
    startScanIntent() {
      this.init()
    },
    startReportIntent() {
      this.init()
    },
    startITRKIntent() {
      this.init()
    },
    startDseIntent() {
      this.loading = true
      Api.legalDocument(this.currentUUID, response => {
        let legalDocument = response.data
        if (!legalDocument) {
          this.progress.progressStep = 2
          this.setStep(2)
        } else {
          this.legalDocument = legalDocument
          this.progress.progressStep = 5
          this.setStep(5)
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },

    // TODO: rename!!!!
    goToPrivacyAgreementInfoPage() {
      this.progress.progressStep = 2
      this.setStep(2)
    },
    goToConfigurationPage() {
      this.progress.progressStep = 3
      this.setStep(3)
    },
    goToFinishPage() {
      this.progress.progressStep = 4
      this.setStep(4)
    },
    goToLegalDocumentDownloadPage() {
      this.progress.currentStep = 5
      this.progress.progressStep = 5
    },
    goToReportDownloadPage() {
      this.progress.currentStep = 6
      this.progress.progressStep = 6
    },

    // Methods
    setStep(updatedStep) {
      if (updatedStep <= this.progress.progressStep) {
        this.progress.currentStep = updatedStep
      }
      this.storeProgressInStorage()
    },
    nextStep() {
      this.progress.currentStep++
      if (this.progress.currentStep > this.progress.progressStep) {
        this.progress.progressStep = this.progress.currentStep
      }
      this.storeProgressInStorage()
    },

    nextStepAfterScan() {
      if (this.intent === 'report' || this.intent === 'itrk') {
        this.goToFinishPage()
      } else {
        this.nextStep()
      }
    },

    startPrivacyAgreementGenerator() {
      this.progress.progressStep = 2
      this.setStep(2)
    },
    sendLegalDocumentAndJumpTo(step) {
      let legalDocForm = {
        websiteScanUUID: this.currentUUID,
        inputJson: this.configurationInputJson,
        legalDocumentId: this.legalDocument ? this.legalDocument.id : null
      }
      Api.sendLegalDocument(this.legalDocumentDefinitionId, legalDocForm, response => {
        this.legalDocument = response.data.legalDocument
        this.currentUUID = response.data.websiteScan.id
        this.storeUUIDInStorage()
        this.progress.progressStep = step
        this.setStep(step)
        this.$emit('agreementCreated', { uuid: this.currentUUID, documentId: this.legalDocument.id })
      }, error => {
        console.log(error)
      })
    },
    // Handlers
    handleSkipGenerator() {
      this.loading = true
      Api.legalDocument(this.currentUUID, response => {
        let legalDocument = response.data
        if (legalDocument) {
          this.legalDocument = legalDocument
        }
        this.progress.progressStep = 4
        this.setStep(4)
        this.loading = false
      }, error => {
        this.loading = false
        console.log(error)
      })
    },
    handleConfigurationDone(configurationInputJson) {
      this.configurationInputJson = configurationInputJson
      if (this.intent === 'dse') {
        this.sendLegalDocumentAndJumpTo(5)
      } else {
        this.sendLegalDocumentAndJumpTo(4)
      }
    },
    handleWebsiteScan(websiteScan) {
      this.websiteScan = websiteScan
      let screenshotBase64String = websiteScan.screenshot?.imageBase64Encoded
      this.currentUUID = websiteScan.id
      this.storeUUIDInStorage()
      storageUtils.setInLegalWebsiteScannerStore('result', {})
      storageUtils.setInLegalWebsiteScannerStore('currentTabIndex', 0)
      this.isNewDse = true
      this.$emit('handleScan', {uuid: this.currentUUID, screenshotBase64String: screenshotBase64String})
    },
    closeScanner() {
      this.$emit('closeScanner')
    },
    applyScanResult() {
      let screenshotBase64String = this.screenshot?.imageBase64Encoded
      this.$emit('applyScanResult', {uuid: this.currentUUID, scanResult: this.tools, screenshotBase64String: screenshotBase64String})
    },
    // LocalStorage Methods
    storeUUIDInStorage() {
      storageUtils.setInLegalWebsiteScannerStore('currentUUID', this.currentUUID)
    },
    restoreUUIDFromStorage() {
      this.currentUUID = storageUtils.getFromLegalWebsiteScannerStore('currentUUID') || this.currentUUID
    },
    storeProgressInStorage() {
      storageUtils.setInLegalWebsiteScannerStore('progress', this.progress)
    },
    restoreProgressFromStorage() {
      this.progress = storageUtils.getFromLegalWebsiteScannerStore('progress') || this.progress
    }
  }
}
</script>

<style lang="scss">
@import "./styles/theme";

.legal-website-scanner {
  font-family: OpenSans-Regular;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark-gray;
  max-width: 1170px;
  min-width: 320px;

  .legal-website-scanner-card {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.13);
  }
}
</style>
