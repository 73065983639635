<template>
  <popper :options="{placement: 'top'}">
    <div class="popper">
      <slot name="popover-content"></slot>
    </div>

    <slot name="popover-trigger-element" slot="reference"></slot>
  </popper>
</template>

<script>
import Popper from 'vue-popperjs';

export default {
  name: "Popover",
  components: {Popper}
}
</script>

<style lang="scss">
</style>
