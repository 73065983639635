<template>
  <div class="tool-editor">
    <div v-if="!definitionGroupCopy" class="row align-content-center py-3 px-3">
      <div v-if="!showUnfilledErrorAlert">
        <div class="col-12 pb-5 text-center">Sie haben in diesem Abschnitt noch nichts ausgewählt</div>
        <img src="../assets/images/img_laptop_grey.png" class="w-100" alt="">
      </div>
      <BAlert :show="showUnfilledErrorAlert"
              variant="danger"
              class="m-0 py-2 w-100 text-center">
        <span>Bitte füllen Sie alle Pflichtfelder aus!</span>
      </BAlert>
    </div>

    <div v-if="definitionGroupCopy">
      <div class="px-4 pt-4 input-definition-title">
        <div class="text-truncate">{{ definitionGroupCopy.title }}</div>
<!--        <div style="font-size: 12px" class="text-muted">some blabla</div>-->
      </div>
      <BTabs v-model="tabIndex" class="custom-horizontal-tab-bar" lazy>
        <!-- Main-Content-Container -->
        <div class="main-content-container" :class="{'consider-activation-btn-height': isEnableableGroup()}">
          <!-- Info Tab -->
          <BTab class="px-4 pt-4">
            <template #title>
            <span style="font-size: 14px">
              <img src="../assets/icons/ic_info.svg" alt="" height="14">
              Info
            </span>
            </template>
            <div>
              {{ definitionGroupCopy.infoText || 'Keine weiteren Information'}}
            </div>
          </BTab>

          <!-- Options Tab -->
          <BTab class="p-4" lazy>
            <template #title>
            <span style="font-size: 14px">
              <img src="../assets/icons/settings_Grey.svg" alt="" height="14">
              Optionen
            </span>
            </template>
            <div>
              <div v-for="(inputDefinition, index) in definitionGroupCopy.inputDefinitions" :key="index">
                <div class="py-2" v-if="inputDefinition.inputType !== 'GroupEnabled'">
                  <div v-if="inputDefinition.infoText"
                       class="pb-2">
                    {{ inputDefinition.infoText }}
                  </div>

                  <div v-if="inputDefinition.inputType === 'String'">
                    <BFormInput v-model="inputDefinition.value"
                                trim
                                @input="emitChange(inputDefinition)"
                                :placeholder="inputDefinition.title"/>
                    <BFormInvalidFeedback>
                      Bitte angeben
                    </BFormInvalidFeedback>
                  </div>

                  <div v-if="inputDefinition.inputType === 'Number'">
                    <BFormInput v-model="inputDefinition.value"
                                type="number"
                                @input="emitChange(inputDefinition)"
                                :placeholder="inputDefinition.title"/>
                    <BFormInvalidFeedback>
                      Bitte angeben
                    </BFormInvalidFeedback>
                  </div>

                  <div v-if="inputDefinition.inputType === 'Boolean'">
                    <BFormCheckbox switch
                                   v-model="inputDefinition.value"
                                   :value="true"
                                   :unchecked-value="false"
                                   @change="emitChange(inputDefinition)"
                    >
                      {{ inputDefinition.title }}
                    </BFormCheckbox>
                  </div>

                  <div v-if="inputDefinition.inputType === 'Choice'">
                    <hr class="mb-0">
                    <BFormRadioGroup v-model="inputDefinition.value"
                    >
                      <BFormRadio v-for="(choice, choiceIndex) in inputDefinition.choices"
                                  class="d-flex align-items-center py-2"
                                  @change="emitChange(inputDefinition)"
                                  :value="choice" :key="choiceIndex">
                        {{ choice }}
                      </BFormRadio>
                    </BFormRadioGroup>
                    <hr class="mt-0">
                  </div>
                </div>
              </div>

            </div>
          </BTab>
        </div>
        <!-- Activation-Button-Container -->
        <div v-if="isEnableableGroup()"
             class="row align-items-center justify-content-center activation-btn-container">
          <BButton v-if="!isEnabledGroup()"
                   variant="primary"
                   @click="enable"
                   pill
                   class="px-4 text-uppercase">
            Aktivieren
          </BButton>
          <BButton v-if="isEnabledGroup()"
                   variant="outline-primary"
                   @click="disable"
                   pill
                   class="px-4 text-uppercase">
            Deaktivieren
          </BButton>
        </div>
      </BTabs>
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BTab,
  BTabs
} from "bootstrap-vue";

export default {
  name: "InputDefinitionEditor",
  props: ['definitionGroup', 'formInputJson', 'showUnfilledErrorAlert'],
  components: {BTab, BAlert, BTabs, BButton, BFormInput, BFormCheckbox, BFormRadioGroup, BFormRadio, BFormInvalidFeedback},
  data() {
    return {
      tabIndex: 1
    }
  },
  watch: {
    definitionGroup() {
      if (this.enableableOnly) {
        // set InfoTab to active if chip is only enableable
        this.tabIndex = 0
      } else {
        // set OptionsTab to active only if there are options to select from
        this.tabIndex = 1
      }
    }
  },
  computed: {
    enableableOnly() {
      if (this.definitionGroup) {
        return this.isEnableableGroup() && this.definitionGroup.inputDefinitions.length === 1
      } else {
        return false
      }
    },
    definitionGroupCopy() {
      let definitionGroupCopy = JSON.parse(JSON.stringify(this.definitionGroup))
      // fill input-fields, checkboxes etc
      if (definitionGroupCopy && this.formInputJson) {
        definitionGroupCopy.inputDefinitions.forEach(inputDefinition => {
          inputDefinition.value = this.formInputJson[inputDefinition.title] || null
        })
      }
      return definitionGroupCopy
    }
  },
  methods: {
    getEnableableInputDefinition() {
      return this.definitionGroupCopy.inputDefinitions.find(inputDefinition => inputDefinition.inputType === 'GroupEnabled')
    },
    isEnableableGroup() {
      return !!this.getEnableableInputDefinition()
    },
    isEnabledGroup() {
      if (this.definitionGroup) {
        let enableableInputDefinition = this.getEnableableInputDefinition()
        if (enableableInputDefinition) {
          return enableableInputDefinition.title in this.formInputJson
        }
        return false
      }
    },
    enable() {
      let inputDefinition = this.getEnableableInputDefinition()
      let data = {
        title: inputDefinition.title,
        value: true
      }
      this.$emit('updatedValue', data)
    },
    disable() {
      let inputDefinition = this.getEnableableInputDefinition()
      let data = {
        title: inputDefinition.title,
        value: false
      }
      this.$emit('updatedValue', data)
    },
    emitChange(inputDefinition) {
      let data = {
        title: inputDefinition.title,
        value: inputDefinition.value
      }
      this.$emit('updatedValue', data)
    },
    required(inputDefinition) {
      return !!inputDefinition.value
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/variables";


.group-name {
  border-bottom: 1px solid $light-gray;
}
</style>
